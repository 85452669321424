/*! Header */

#header {
  background-color: var(--dark-green-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

#header .orange-block::before {
  min-width: 100%;
  max-width: 100%;
}

#header h3 {
  position: relative;
}

#header h3::after {
  content: "KT";
  color: var(--dark-green-2);
  top: 10%;
  left: 10%;
  bottom: 5%;
  position: absolute;
  z-index: -1;
}

#header ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

#header li {
  padding: 0 0.5rem;
}

/*! Footer */

#footer {
  background-color: var(--dark-green-1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  font-size: 80%;
}

@media (min-width: 600px) {
  #header {
    padding: 0 2rem;
  }

  #header li {
    padding: 0 1rem;
  }

  #footer {
    padding: 0 2rem;
  }
}

@media (min-width: 800px) {
  #header {
    padding: 0 3rem;
  }

  #header li {
    padding: 0 2rem;
  }

  #footer {
    padding: 0 3rem;
  }
}

@media (min-width: 1200px) {
  #header {
    padding: 0 4rem;
  }

  #footer {
    padding: 0 4rem;
  }
}
.card {
    height: 450px;
    min-width: 200px;
    background-color: var(--dark-green-2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.5s;
    margin-top: 3rem;
}
  
.card img, .card picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
  
.card-view img, .card picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
  
.details {
    opacity: 0;
    background-color: rgba(188, 108, 37, 0.9);
    transition: 0.5s;
    position: absolute;
    z-index: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    /* overflow: hidden; */
}
  
.card:hover .details {
    opacity: 100;
    transition: 0.5s;
}
  
.card:hover {
    transition: 0.5s;
    transform: scale(0.95, 0.95);
    box-shadow: 2px 2px 10px var(--dark-green-2);
}

/* .blurb {
    overflow: hidden;
    text-overflow: ellipsis;
} */

.details h3, .details p {
    margin: 0.5rem;
}

@media (min-width: 400px) {
    .details {
        padding: 0 3rem;
    }

    .details h3, .details p {
        margin: 1rem;
    }
}
/* Intro */

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headshot {
    flex-basis: 100vw;
}

.headshot img {
    object-fit: cover;
    width: 100vw;
    height: 100%;
    max-height: 80vh;
}

.text-container {
    flex-basis: 100%;
    padding: 0 1rem;
}

/*! Media Queries */

@media (min-width: 600px) {
    .intro {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .headshot {
        flex-basis: 41.666%;
    }

    .headshot img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 80vh;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        flex-basis: 58.333%;
        justify-content: center;
        padding: 0 2rem;
    }

}

@media (min-width: 800px) {
    .headshot {
        flex-basis: 33%;
    }

    .text-container {
        flex-basis: 66%;
        justify-content: center;
    }
}



/* Tech Icons */

#tech-box {
    display: flex;
    justify-content: space-evenly;
    font-size: 200%;
    padding: 1rem 0;
}
  
#tech-box .tech {
    transition: 0.5s;
    position: relative;
    display: inline-block;
    z-index: 100;
}
  
#tech-box .tech:hover {
    color: var(--dark-green-1);
    transition: 0.5s;
}
  
#tech-box .tech::before {
    content: "";
    width: 120%;
    height: 90%;
    position: absolute;
    transition: 0.5s;
    top: 15%;
    bottom: -5%;
    left: 5%;
    transform: translateY(0px);
    z-index: -1;
    background: var(--dark-green-2);
}
  
#tech-box .tech:hover::before {
    background: var(--dark-orange-2);
    transform: translateY(3px);
}

